<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">

              <div class="col-8 ">
                <h4>
                  <router-link :to="{name:'role'}">
                    <i class="fas fa-arrow-left mr-2"></i>
                  </router-link>
                  {{ role.name }}'s Permissions
                </h4>
              </div>
              <div class="col-4 text-right">
                <v-btn depressed class="btn btn-primary text-white" @click="savePermission">Save Permission</v-btn>
              </div>


              <div class="table-responsive mt-2">
                <!--                <pre>{{selected_permissions}}</pre>-->
                <table class="table">
                  <thead class="text-left">
                  <th>{{ (role.name) ? role.name.toUpperCase() : null }}</th>
                  <!--                  <th> ({{ role_permissions && role_permissions.length > 0 ? role_permissions.length : 0 }})-->
                  <!--                    Assigned Permissions-->
                  <!--                  </th>-->
                  </thead>
                  <tbody class="text-left">
                  <tr>
                    <th>
                      <v-checkbox v-if="role.name != 'admin' "
                                  v-model="status"
                                  @change="selectAll()"
                                  outlinde
                                  dense
                                  label="Select All"
                      >
                      </v-checkbox>
                    </th>
                    <th>permission</th>
                  </tr>
                  <tr v-for="(permission_list, i) of permissions" :key="i" v-if="permission_list.length>0">
                    <!--                    <pre>{{ permissions }}</pre>-->
                    <td class="text-center">
                      <label>{{ i.replace("_", " ").toUpperCase() }}</label>
                    </td>
                    <td>
                      <v-row>
                        <v-col cols="3" v-for="(permission, j) of permission_list" :key="j">
                          <v-switch v-model="selected" :value="permission" :label="permission"></v-switch>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </v-app>
</template>

<script>
import RoleCustomPermission from "@/services/user/role/RoleCustomPermission";
import RoleService from "@/services/user/role/RoleService";
import {mapGetters} from "vuex";

const roleService = new RoleService();
const customPermission = new RoleCustomPermission();
export default {
  name: "ManageRoleCustomPermission",
  data() {
    return {
      permissions: [],
      selected: [],
      role: {},
      status: false,
    }
  },
  computed: {
    role_id() {
      return this.$route.params.id;
    },
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.getRole();
    this.getAllSubmenuPermissions();
    this.getAssignedPermission();
  },
  methods: {
    getRole() {
      roleService.show(this.role_id).then(response => {
        this.role = response.data.role;
      });
    },
    getAllSubmenuPermissions() {
      customPermission.getAllSubmenuPermissions().then(response => {
        this.permissions = response.data;
      })
    },
    getAssignedPermission() {
      customPermission.assignedPermissionToRole(this.role_id).then(response => {
        this.selected = [];
        if (response.data.permissions && response.data.permissions.length > 0) {
          response.data.permissions.forEach(ele => {
            this.selected.push(ele)
          })
        }
      })
    },
    selectAll() {
      if (this.status) {
        for (let permission in this.permissions) {
          for (let list of this.permissions[permission]) {
            this.selected.push(list);
          }
        }
      } else {
        this.selected = [];
      }
    },
    savePermission() {
      let data = {role: this.role.name, permissions: this.selected};
      customPermission.savePermission(data).then(response => {
        this.$snotify.success("Permission assigned to role successfully")
        this.getAssignedPermission();
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      })
    }
  }
}
</script>

<style scoped>

</style>